import React from 'react';
import { Typography, Link as MUILink } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import msflogo from 'files/my_spirit_factory_logo.jpg';
import evelyne from 'files/evelyne.jpg';
import ecclogo from 'files/ecc-logo.png';
import mgconseils from 'files/mg-conseils.png';
import mgphoto from 'files/mg-photo.png';
import donilogo from 'files/doni-logo.jpg';
import donipohoto from 'files/doni-photo.jpg';
import jeromesavoye from 'files/jerome_savoye.jpeg';
import vitilogo from 'files/viti-logo.jpg';
import satiflogo from 'files/satif-logo.jpg';
import ldslogo from 'files/lds-logo.jpg';
import dujardinlogo from 'files/dujardin-logo.jpg';
import cidslogo from 'files/cids-logo.jpg';
import oenologo from 'files/oeno_logo.jpg';
import flamellogo from 'files/flamel.jpg';
import jlbphoto from 'files/jlbphoto.jpg';
import almphoto from 'files/almphoto.jpg';
import almlogo from 'files/almlogo.jpg';
import { ContactCard, OrangeText, DoubleText } from './ContactCard';

export default function Experts(props) {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flexFlow: 'column wrap', alignItems: 'center' }}>
      <Typography variant="h4" component="span">
        {t('experts.experts_title')}
      </Typography>

      <Typography
        variant="subtitle2"
        component="p"
        sx={{
          mb: 3,
        }}
      >
        {t('experts.experts_sub_title')}
        <MUILink
          component={Link}
          to="/contact"
          color="primary"
          sx={{ fontWeight: 'bold' }}
        >
          contact@labox-apps.com
        </MUILink>
      </Typography>

      <Typography variant="h4" component="span" sx={{ color: 'primary.main' }}>
        {t('experts.independants')}
      </Typography>

      <ContactCard
        link="https://www.mgconseils-winespirits.fr/"
        images={(
          <>
            <img src={mgconseils} style={{ width: 120, height: 'auto', marginBottom: 30 }} alt="mg-conseils" />
            <img src={mgphoto} style={{ width: 120, height: 'auto' }} alt="mg" />
          </>
      )}
        text={(
          <>
            <Typography variant="body2" color="textSecondary" component="span">
              Contact: Marc GIBOULOT -
              {' '}
              {t('experts.marc')}
            </Typography>
            <br />
            <DoubleText text1={t('experts.expertise_advice')} text2={t('experts.expertise_advice_adt')} />
            <OrangeText text={t('experts.tech_help')} />
            <Typography variant="body2" color="textSecondary" component="span" style={{ marginLeft: 30 }}>
              {t('experts.tech_help_adt', { joinArrays: '\n' })}
            </Typography>
            <DoubleText text1={t('experts.expert')} text2={t('experts.expert_adt')} />
            <DoubleText text1={t('experts.formation')} text2={t('experts.formation_marc_adt_')} />
          </>
        )}
      />
      <ContactCard
        link="https://innovin.fr/pool-conseil/doni-d/"
        images={(
          <>
            <img src={donilogo} style={{ width: 120, height: 'auto', padding: 15 }} alt="doni-logo" />
            <img src={donipohoto} style={{ width: 120, height: 'auto' }} alt="doni" />
          </>
      )}
        text={(
          <>
            <Typography variant="body2" color="textSecondary" component="span">
              Contact: Thierry ARCHEREAU -
              {' '}
              {t('experts.thierry')}
            </Typography>
            <br />
            <DoubleText text1={t('experts.expertise_advice')} text2={t('experts.expertise_advice_adt')} />
            <OrangeText text={t('experts.tech_help')} />
            <Typography variant="body2" color="textSecondary" component="span" style={{ marginLeft: 30 }}>
              {t('experts.tech_help_thierry_adt', { joinArrays: '\n' })}
            </Typography>
            <DoubleText text1={t('experts.teacher')} text2={t('experts.techer_adt')} />
            <DoubleText text1={t('experts.formation')} text2={t('experts.formation_thierry_adt')} />
          </>
        )}
      />
      <ContactCard
        link="https://www.myspiritfactory.com/"
        images={(
          <>
            <img src={msflogo} style={{ width: 120, height: 'auto' }} alt="msf" />
            <img src={jeromesavoye} style={{ width: 120, height: 'auto' }} alt="jerome" />
          </>
      )}
        text={(
          <>
            <Typography variant="body2" color="textSecondary" component="span">
              {'Contact: Jérôme Savoye - Master II : Commerce, Droit & Gestion des Spiritueux'
              + ' // Commerce International, Segonzac (2015)'}
            </Typography>
            <br />
            <OrangeText text="EXPERTISE / CONSEILS" />
            <Typography variant="body2" color="textSecondary" component="span" style={{ marginLeft: 30 }}>
              {
                'Management de projet\nDéveloppement packaging\nMise en place et gestion de la production de spiritueux'
                }
            </Typography>
          </>
        )}
      />
      <ContactCard
        images={(
          <>
            <img
              src={ecclogo}
              style={{
                width: 100, height: 'auto', padding: 5, marginRight: 10,
              }}
              alt="ecc-logo"
            />
            <img
              src={evelyne}
              style={{
                width: 100, height: 'auto', padding: 5, marginRight: 10,
              }}
              alt="evelyne"
            />
          </>
      )}
        text={(
          <>
            <Typography variant="body2" color="textSecondary" component="span">
              Contact: Evelyne Chanson -
              {' '}
              {t('experts.evelyne')}
            </Typography>
            <br />
            <OrangeText text={t('experts.advice')} />
            <DoubleText text1={t('experts.choice')} text2={t('experts.choice_adt')} />
            <DoubleText text1={t('experts.practice')} text2={t('experts.practice_adt')} />
            <DoubleText text1={t('experts.expert')} text2={t('experts.expert_adt')} />
            <DoubleText text1={t('experts.formation')} text2={t('experts.formation_adt')} />
          </>
        )}
      />
      <ContactCard
        link="https://www.linkedin.com/in/jean-luc-braud-0bb3a3b/?originalSubdomain=fr"
        images={(
          <img src={jlbphoto} style={{ width: 120, height: 'auto' }} alt="jlb" />
      )}
        text={(
          <>
            <Typography variant="body2" color="textSecondary" component="span">
              Contact: Jean-Luc BRAUD
              {' '}
              {t('experts.jlb')}
            </Typography>
            <br />
            <DoubleText text1={t('experts.expert_support')} text2={t('experts.expert_support_jlb')} />
          </>
        )}
      />
      <ContactCard
        link="https://almconseil.fr/"
        images={(
          <>
            <img src={almlogo} style={{ width: 120, height: 'auto', marginBottom: 30 }} alt="alm-conseils" />
            <img src={almphoto} style={{ width: 120, height: 'auto' }} alt="alm" />
          </>
      )}
        text={(
          <>
            <Typography variant="body2" color="textSecondary" component="span">
              Contact: Anne-Lise ARRIAU MAILLET
            </Typography>
            <br />
            <DoubleText text1={t('experts.support')} text2={t('experts.support_alm')} />
            <DoubleText text1={t('experts.audit_intern')} text2={t('experts.audit_intern_alm')} />
            <DoubleText text1={t('experts.formations')} text2={t('experts.formations_alm')} />
          </>
        )}
      />

      <Typography variant="h4" component="span" sx={{ color: 'primary.main', mt: 3 }}>
        {t('experts.experts_production')}
      </Typography>
      <ContactCard
        link="https://www.vitidirect.fr/"
        images={(
          <>
            <img src={vitilogo} style={{ width: 120, height: 'auto', marginBottom: 15 }} alt="viti" />
            <img src={satiflogo} style={{ width: 120, height: 'auto' }} alt="satif" />
          </>
      )}
        text={(
          <>
            <Typography variant="body2" color="textSecondary" component="span">
              Contact: Francois BRETHENOUX
            </Typography>
            <br />
            <OrangeText text={t('experts.material')} />
            <DoubleText text1={t('experts.expertise_sell')} text2={t('experts.expertise_sell_adt')} />
            <DoubleText text1={t('experts.chaudronnier')} text2={t('experts.chaudronnier_adt')} />
          </>
        )}
      />
      <Typography variant="h4" component="span" sx={{ color: 'primary.main', mt: 3 }}>
        {t('experts.ingredients')}
      </Typography>
      <ContactCard
        link="http://flamelaromatic.fr/"
        images={(
          <img src={flamellogo} style={{ width: 120, height: 'auto' }} alt="flamel" />
      )}
        text={(
          <>
            <Typography variant="body2" color="textSecondary" component="span">
              {'Contact: Daniel LE DOUARON – Directeur général – Fondateur de Flamel Aromatic –'
              + ' Plus de 40 ans d\'expérience dans le domaine de l\'Industrie alimentaire dont les spiritueux.'}
            </Typography>
            <br />
            <OrangeText text="VENTE" />
            <Typography variant="body2" color="textSecondary" component="span" style={{ marginLeft: 30 }}>
              {
                'Arômes alimentaires\nCréation et production\nR&D et Innovation pour l\'industrie agro-alimentaire'
                }
            </Typography>
            <OrangeText text="CONSEILS" />
            <Typography variant="body2" color="textSecondary" component="span" style={{ marginLeft: 30 }}>
              {
                'Mise au point de nouveaux produits ou l\'amélioration de recettes existantes\n'
                + 'Optimisation des outils et process de fabrication'
                }
            </Typography>
          </>
        )}
      />
      <Typography variant="h4" component="span" sx={{ color: 'primary.main', mt: 3 }}>
        {t('experts.experts_packaging')}
      </Typography>
      <ContactCard
        link="https://www.facebook.com/myspiritfactory"
        images={(
          <>
            <img src={msflogo} style={{ width: 120, height: 'auto' }} alt="msf" />
            <img src={jeromesavoye} style={{ width: 120, height: 'auto' }} alt="jerome" />
          </>
      )}
        text={(
          <>
            <Typography variant="body2" color="textSecondary" component="span">
              {'Contact: Jérôme Savoye - Master II : Commerce, Droit & Gestion des Spiritueux'
              + ' // Commerce International, Segonzac (2015)'}
            </Typography>
            <br />
            <OrangeText text="EXPERTISE / CONSEILS" />
            <Typography variant="body2" color="textSecondary" component="span" style={{ marginLeft: 30 }}>
              {
                'Développement packaging\nPour acheter ou vendre des stocks de bouteilles, carafes, capsules'
                }
            </Typography>
          </>
        )}
      />
      <Typography variant="h4" component="span" sx={{ color: 'primary.main', mt: 3 }}>
        {t('experts.experts_material')}
      </Typography>
      <ContactCard
        link="https://www.dujardin-salleron.com/"
        images={(
          <>
            <img
              src={ldslogo}
              style={{
                width: 120, height: 'auto', marginBottom: 15, padding: 10,
              }}
              alt="lds"
            />
            <img src={dujardinlogo} style={{ width: 120, height: 'auto' }} alt="dujardin" />
          </>
      )}
        text={(
          <>
            <Typography variant="body2" color="textSecondary" component="span">
              Contact: Antoine GRUAU (France) / Anne HIDALGO (International)
            </Typography>
            <br />
            <OrangeText text={t('experts.lab_material')} />
            <DoubleText text1={t('experts.builder')} text2={t('experts.builder_adt')} />
            <DoubleText text1={t('experts.control')} text2={t('experts.control_adt')} />
            <DoubleText text1={t('experts.distrib')} text2={t('experts.distrib_adt')} />
          </>
        )}
      />
      <Typography variant="h4" component="span" sx={{ color: 'primary.main', mt: 3 }}>
        {t('experts.experts_laboratory')}
      </Typography>
      <ContactCard
        link="https://www.oenoweb.fr/"
        images={(
          <img src={oenologo} style={{ width: 120, height: 'auto', backgroundColor: '#74992e' }} alt="oene" />
      )}
        text={(
          <>
            <Typography variant="body2" color="textSecondary" component="span">
              {'Contact: Olivier CHAPT – Consultant-œnologue, diplômé de l\'Université de Reims Champagne – '
              + 'Ardennes en 1999,  23 ans d\'expérience dans le Domaine des Vins et Spiritueux  - '
              + 'Propriétaire et Directeur du Laboratoire depuis 2010'}
            </Typography>
            <br />
            <DoubleText
              text1="ANALYSES"
              text2={'sur la majorité des paramètres essentiels au suivi de la production des vins, '
              + 'spiritueux et boissons alcoolisées.'}
            />
            <Typography variant="body2" color="textSecondary" component="span" style={{ marginLeft: 30 }}>
              {
                'Accrédité CoFrAc (portée n°1-0958), section d\'essai depuis 1994\n'
                + 'Analyses officielles et concours: Agréé par la DGCCRF pour la délivrance de certificats\n'
                + 'd\'analyses et de pureté reconnus à l\'export et par France Agri-Mer'
                }
            </Typography>
            <DoubleText
              text1="CONSEILS"
              text2="en élaboration, vinification et distillation"
            />
            <DoubleText
              text1="VENTE"
              text2="de produits œnologiques"
            />
          </>
        )}
      />
      <Typography variant="h4" component="span" sx={{ color: 'primary.main', mt: 3 }}>
        Formation
      </Typography>
      <ContactCard
        link="https://www.centre-spirits.org/"
        images={(
          <img src={cidslogo} style={{ width: 120, height: 'auto', backgroundColor: 'red' }} alt="cids" />
      )}
        text={(
          <>
            <Typography variant="body2" color="textSecondary" component="span">
              Contact: Quitterie FOURQUET -
              {' '}
              {t('experts.quitterie')}
            </Typography>
            <br />
            <DoubleText text1={t('experts.obs')} text2={t('experts.obs_adt')} />
            <DoubleText text1={t('experts.formations')} text2={t('experts.formations_adt')} />
          </>
        )}
      />
    </div>
  );
}
