import React from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren, matchRoutes, useLocation, useNavigationType,
} from 'react-router-dom';

const initializeSentry = () => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://c519c9e99f0eceb436cf6de766669f2f@o4506297457246208.ingest.sentry.io/4506297485492224',
      integrations: [
        new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
        new Sentry.Replay(),
        Sentry.captureConsoleIntegration({
          levels: ['error'],
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.2,
      tracePropagationTargets: [
        'localhost',
        /^https?:\/\/alcoholometry\.labox-apps\.com\/.*/,
      ],
      // Session Replay
      // This sets the sample rate at 10%. You may want to change it to 100%
      // while in development and then sample at a lower rate in production.
      replaysSessionSampleRate: 0.1,
      // If you're not already sampling the entire session, change the sample
      // rate to 100% when sampling sessions where errors occur.
      replaysOnErrorSampleRate: 1.0,
    // debug: true,
    });
  } else {
    console.log('Sentry is disabled in development mode.');
  }
};

export default initializeSentry;
